body {
	font-size: 20px;
	font-family: Arial, Helvetica, sans-serif;
}

input {
	margin: 5px;
	font-weight: 700;
	font-size: 45px;
	max-width: 100%;
}

/* items */
.itemsList {
	background-color: aliceblue;
	padding: 10px;
}

.item,
.itemHeaderContainer {
	flex-direction: row;
	flex: 1;
	background-color: yellow;
	padding: 10px;
}

.itemHeader {
	background-color: khaki;
	padding: 10px;
	font-weight: 700;
	font-size: large;
}

.item .itemText {
	background-color: yellowgreen;
	padding: 10px;
}

.item .itemDescription {
	font-style: italic;
	font-size: smaller;
}

.item .itemPrice {
	background-color: lime;
	padding: 10px;
}

.item .itemNbrPurchased {
	background-color: burlywood;
	padding: 10px;
}

.item .itemTotal {
	background-color: royalblue;
	padding: 10px;
	font-size: 1.6em;
}

/*header*/

.header {
	padding: 10px;
	width: 100%;
	background-color: orange;
}

.logo {
	background-color: aqua;
}

.title {
	background-color: blueviolet;
}

.totalen {
	background-color: orangered;
}

.totaal {
	background-color: brown;
}

.received {
	background-color: beige;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
